<template>
    
      <!-- Main content -->
      <section class="content">
        <div class="btn-box">
            <el-button type="primary" size="mini" @click="add">添加平台</el-button> 
        </div>
        <el-table
        v-loading="loading"
            :data="tableData"
            border
            style="width: 100%">
            <el-table-column
                fixed
                prop="id"
                label="id"
            >
            </el-table-column>
            <el-table-column
                fixed
                prop="title"
                label="页面标题"
            >
            </el-table-column>
<el-table-column label="平台logo">
    <template slot-scope="scope">
                    <el-image :src="scope.row.logo" :preview-src-list="[scope.row.logo]"></el-image>
                </template>
</el-table-column>
<el-table-column prop="name" label="平台名称">
</el-table-column>
<el-table-column prop="button_copy" label="按钮文案">
</el-table-column>
<el-table-column label="跳转类型">
    <template slot-scope="scope">
                    <span>{{scope.row.redirect_type | redirect}}</span>
                </template>
</el-table-column>
<el-table-column prop="page_url" label="页面链接">
    <template slot-scope="scope">
        {{scope.row.page_url}}
        <el-button type="text" size="small" v-clipboard:copy=scope.row.page_url v-clipboard:success="copy" >复制</el-button>
    </template>
</el-table-column>
<el-table-column fixed="right" label="操作" width="150">
    <template slot-scope="scope">
                    <el-button type="danger" size="mini" @click="del(scope.row.id)">删除</el-button>
                    <el-button type="primary" size="mini" @click="alret(scope.row.id)">修改</el-button>
                </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>

<platformDialog :showExport="show" @clickClose="close" :platform="platform"></platformDialog>
</section>
<!-- /.content -->
</template>

<script>
    import * as api from '@/config/api'
    import {
        md5
    }
    from '@/config/md5'
    export default {
        name: 'platform',
        components: {},

        data() {
            return {
                tableData: [],
                total: 0,
                show: false,
                page: 1,
                pagesize: 10,
                platform: {},
                loading: true
            }
        },
        watch: {},
        filters: {
            redirect(val) {
                let common = JSON.parse(window.localStorage.getItem('common'))
                let name = ''
                if (JSON.stringify(common.redirect_type_list).indexOf(val) != -1) {
                    common.redirect_type_list.map((value, index) => {
                        if (val === value.type) {
                            name = value.name
                        }
                    })
                }
                return name
            }
        },
        mounted() {
            this.getPlatformPageList()
        },
        methods: {
            close() {
                this.show = false
                this.getPlatformPageList()
            },
            getPlatformPageList() {
                this.loading = true
                api.getPlatformPageList({
                    page: this.page,
                    pagesize: this.pagesize
                }, res => {
                    this.tableData = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getPlatformPageList()
            },
            currentChange(val) {
                this.page = val
                this.getPlatformPageList()
            },
            add() {
                this.platform = {}
                this.show = true
            },
            copy() {
                this.$message({
                    message: '复制成功',
                    type: 'success'
                })
            },
            del(id) {
                this.$confirm('平台删除后不可恢复，你还要继续吗？', '请确定是否删除平台', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    api.delPlatformPage({
                        id: id
                    }, res => {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getPlatformPageList()
                    })
                }).catch(() => {})
            },
            alret(id) {
                api.getPlatformPage({
                    id: id
                }, res => {
                    this.platform = res.data.item
                    this.show = true
                })
            },
        }
    }
</script>
<style scoped>
    .el-table .cell {
        text-align: center;
    }
    
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-image img {
        height: 40px;
    }
    
    .el-image-viewer__canvas img {
        width: auto;
        height: auto;
    }
</style>